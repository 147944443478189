import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Account from './Components/Account';


const Profile = () => {


  return (
    <div className=''>

      <Header />
      <Account />
      <Footer />
    
  </div>
  )
};

export default Profile;