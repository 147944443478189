import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';

function WebRTC() {
  const [ipAddresses, setIpAddresses] = useState([]);
  const [rtcPeerConnection, setRtcPeerConnection] = useState(null);
  const [rtcDataChannel, setRtcDataChannel] = useState(null);
  const [publicIp, setPublicIp] = useState('');
  const [localIp, setLocalIp] = useState('');
  const [webRtcLeakTestResult, setWebRtcLeakTestResult] = useState('');

  useEffect(() => {
    // Create a new RTCPeerConnection
    const pc = new RTCPeerConnection();
    setRtcPeerConnection(pc);

    // Create a new RTCDataChannel
    const dc = pc.createDataChannel('myDataChannel');
    setRtcDataChannel(dc);

    // Get the IP addresses
    pc.onicecandidate = (event) => {
      if (event.candidate) {
        const ipAddress = event.candidate.candidate.split(' ')[4];
        setIpAddresses((prevIpAddresses) => [...prevIpAddresses, ipAddress]);
      }
    };

    // Get the public IP address
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => setPublicIp(data.ip));

    // Get the local IP address
    fetch('https://api.ipify.org?format=json&local=true')
      .then((response) => response.json())
      .then((data) => setLocalIp(data.ip));

    // Perform the WebRTC Leak Test
    pc.oniceconnectionstatechange = () => {
      if (pc.iceConnectionState === 'connected') {
        setWebRtcLeakTestResult('WebRTC Leak Test: FAILED');
      } else {
        setWebRtcLeakTestResult('WebRTC Leak Test: PASSED');
      }
    };
  }, []);

  return (
    <div>
      <Header />
      <div className="md:container bg-light/15 border border-gray-200 mx-8 md:mx-auto px-4 py-8 rounded-xl my-8">
      
        <h1 className="text-2xl font-bold justify-center">WebRTC Leak Test</h1>
        <hr  className='mb-4'/>
        <div className='flex flex-row items-center mb-2'>
          <p className='font-semibold mr-2'>Exposed IP Addresses: </p>
          <ul>
            {ipAddresses.length > 0 ? (
              ipAddresses.map((ipAddress, index) => (
                <li key={index}>{ipAddress}</li>
              ))
            ) : (
              <li>None</li>
            )}
          </ul>
        </div>
        <hr  className='mb-4'/>
        
        <div className='flex flex-row items-center'>
          <p className='font-semibold mr-2'>RTCPeerConnection Result: </p>
          <p>{rtcPeerConnection ? 'Connected' : 'Not Connected'}</p>
        </div>

        <div className='flex flex-row items-center'>
          <p className='font-semibold mr-2'>RTCDataChannel Result:</p>
          <p> {rtcDataChannel ? 'Open' : 'Closed'}</p>
        </div>
        
        <hr  className='mb-4'/>
        
        <div className='flex flex-row items-center'>
          <p className='font-semibold mr-2'>Public IP Address:</p>
          <p> {publicIp}</p>
        </div>

        <div className='flex flex-row items-center'>
          <p className='font-semibold mr-2'>Local IP Address:</p>
          <p> {localIp}</p>
        </div>
        
        <hr  className='mb-4'/>

        <div className='flex flex-row items-center'>
          <p className='font-semibold mr-2'>WebRTC Leak Test Result:</p>
          <p> {webRtcLeakTestResult ? webRtcLeakTestResult : 'None'}</p>
        </div>
        
      </div>

      <div className="md:container bg-light/15 border border-gray-200 mx-8 md:mx-auto px-4 py-8 rounded-xl my-8">
        <h2 className="text-2xl font-semibold mb-4">What is WebRTC Leak?</h2>
          <p className="text-gray-700 mb-8 ml-4">
            {"WebRTC (Web Real-Time Communication) is a technology that enables peer-to-peer communication directly between web browsers. A “WebRTC leak” refers to the unintended exposure of a user’s real IP address during a WebRTC session, even when they are using a VPN or proxy service that is supposed to mask their IP address. This can occur because WebRTC can establish direct connections between peers without routing traffic through the VPN or proxy, potentially revealing the user’s true IP address to other participants in the communication."}
            {"The leak happens due to how WebRTC operates; it uses STUN (Session Traversal Utilities for NAT) servers to discover public IP addresses and facilitate connections. If these settings are not properly configured or if the user is unaware of WebRTC’s behavior, their real IP may be exposed."}
          </p>

          <h2 className="text-2xl font-semibold mb-4">RTCPeerConnection Result</h2>
          <p className="text-gray-700 mb-8 ml-4">
            {"RTCPeerConnection is an essential component of the WebRTC API that manages the connection between two peers. It handles media streams (audio/video) and data channels, enabling real-time communication. The RTCPeerConnection object provides methods for establishing connections, managing ICE candidates (Interactive Connectivity Establishment), and handling media streams."}
          </p>

          <h2 className="text-2xl font-semibold mb-4">RTCDataChannel</h2>
          <p className="text-gray-700 mb-8 ml-4">
            {"RTCDataChannel is another component of the WebRTC API that allows for bi-directional data transfer between peers over a WebRTC connection. It enables applications to send arbitrary data (text, binary files) directly between clients without needing to go through a server."}
          </p>

          <h2 className="text-2xl font-semibold mb-4">WebRTC Leak Test Results</h2>
          <p className="text-gray-700 mb-8 ml-4">
            {"A WebRTC leak test evaluates whether your real IP address can be exposed while using services like VPNs or proxies during a WebRTC session. Various online tools allow users to check for leaks by establishing a connection and analyzing network requests made by their browser."}
          </p>

          <h2 className="text-xl font-semibold mb-4">In summary</h2>
          <p className="flex  flex-col text-gray-700 mb-8 gap-y-2 ml-4">
            <p>A WebRTC leak exposes your real IP despite using privacy tools.</p>
            <p>An RTCPeerConnection result signifies successful peer-to-peer connectivity for media/data transmission.    </p>
            <p>An RTCDataChannel facilitates direct data transfer between peers.</p>
            <p>A WebRTC leak test result determines if your actual IP address remains hidden during usage.</p>
          </p>

      </div>
      <Footer />
    </div>
  );
}

export default WebRTC;