import React from 'react';
import Header from './Header';
import Footer from './Footer';
import img1 from '../assets/img/feature1-img.png';
import img2 from '../assets/img/feature2-img.png';
import img3 from '../assets/img/feature3-img.png';


const Features = () => {
    return (
        <div>
            <Header />
            <div className="md:container bg-light/25 mx-8 md:mx-auto px-4 py-8 rounded-xl my-8 text-justify">
                <h1 className="text-4xl font-semibold mb-8 text-center">
                    Experience the Ultimate VPN Service 
                </h1>
                <h1 className="text-3xl font-semibold mb-8 text-center">
                    Unlock a Reliable, Fast, and Secure Internet Experience
                </h1>
                <p className="text-gray-800 mb-8 ml-4">
                    {'In today’s digital age, online privacy and security are paramount. Our VPN service is designed to provide you with a seamless browsing experience that prioritizes your safety and speed. With ultra-fast servers located around the globe, we ensure that you can access content from anywhere without compromising on performance.'}
                </p>

                <div className='flex flex-col lg:flex-row lg:items-start lg:gap-x-[10px]'>

                    <div className='flex-1' >
                        <h2 className="text-2xl font-semibold mb-4 mt-8">Reliable Performance You Can Trust</h2>
                        <p className="text-gray-800 mb-8 ml-4">
                            {'Our VPN guarantees reliable connectivity, allowing you to browse the internet without interruptions. Whether you’re streaming your favorite shows or working remotely, our service maintains a stable connection that adapts to your needs.'}
                        </p>

                        <h2 className="text-2xl font-semibold mb-4">Ultra-Fast Servers Around the World</h2>
                        <p className="text-gray-800 ml-4">
                            {'With strategically placed servers across multiple countries, our VPN offers ultra-fast speeds that enhance your online activities. Enjoy buffer-free streaming and quick downloads as our technology optimizes your connection for maximum efficiency.'}
                        </p>
                    </div>

                    <div className='flex-1 items-center'>
                        <img src={img1} alt='feature'/>
                    </div>
                </div>

                <div className='flex flex-col lg:flex-row lg:items-start lg:gap-x-[10px]'>

                    <div className='flex-1 order-2 lg:order-1'>
                        <img src={img2} alt='feature'/>
                    </div>

                    <div className='flex-1 order-1 lg:order-2'>
                        <h2 className="text-2xl font-semibold mb-4 mt-8">Zero Trust Policy for Enhanced Security</h2>
                        <p className="text-gray-800 mb-8 ml-4">
                            {'Our zero trust policy means that we never log your activity or personal information. Your privacy is our priority, ensuring that you can browse anonymously without fear of surveillance or data breaches.'}
                        </p>

                        <h2 className="text-2xl font-semibold mb-4">Safer and More Open Internet Access</h2>
                        <p className="text-gray-800 ml-4">
                            {'We believe in providing a safer and more open internet for everyone. Our VPN encrypts your data, protecting it from prying eyes while allowing you to bypass geo-restrictions. Experience true freedom online with unrestricted access to websites and services.'}
                        </p>
                    </div>
                </div>

                <div className='flex flex-col lg:flex-row lg:items-start lg:gap-x-[10px]'>

                    <div className='flex-1' >
                        <h2 className="text-2xl font-semibold mb-4 mt-8">24/7 Support for Peace of Mind</h2>
                        <p className="text-gray-800 mb-8 ml-4">
                            {'Our dedicated support team is available 24/7 to assist you with any questions or issues you may encounter. We are committed to providing exceptional customer service so that you can enjoy a worry-free experience with our VPN.'}
                        </p>

                        <h2 className="text-2xl font-semibold mb-4">Unlimited Devices and Traffic</h2>
                        <p className="text-gray-800 mb-8 ml-4">
                            {'Connect as many devices as you want without worrying about bandwidth limits. Our VPN allows unlimited device connections and traffic usage, making it perfect for families or individuals who use multiple devices simultaneously.'}
                        </p>
                        <p className="text-gray ml-4">
                            {'Experience the ultimate in online privacy and security with our reliable, fast, and secure VPN service today!'}
                        </p>
                    </div>

                    <div className='flex-1' >
                        <img src={img3} alt='feature'/>
                    </div>
                </div>

                
            </div>

            <Footer />
        </div>
    )
};

export default Features;
