import React, { useState } from 'react';
import { HiCheck } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../Header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';


const plans = [
    { id:1, title: '1-Month', initPrice:'$9.99', price: '$4.99', moneyBack: '14-days', save: '50%',  services: [{ name: 'High-speed, Secure VPN' },{ name: 'Malware and threat protection'},{ name: 'Unlimited traffic' }]},
    { id:2, title: '1-Year', initPrice:'$9.99', price: '$2.49', moneyBack: '30-days', save: '75%', services: [{ name: 'High-speed, Secure VPN' },{ name: 'Malware and threat protection'},{ name: 'Unlimited traffic' }]},
    { id:3, title: '6-Months', initPrice:'$9.99', price: '$3.99', moneyBack: '30-days', save: '60%', services: [{ name: 'High-speed, Secure VPN' },{ name: 'Malware and threat protection'},{ name: 'Unlimited traffic' }]},
];

const PricingPage = () => {
    const [index, setIndex] = useState(1);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        const token = localStorage.getItem('accessToken');

        if (token) {
            axios.get('https://api.surfheim.com/authentication/', {
                headers: { 'x-access-token': token }
            }).then(response => {
                if (response.status === 200) {
                    navigate('/Order/')
                } else {
                    navigate('/Sign-in/')
                }}
            ).catch(error => {
                setLoading(false);
                navigate('/Sign-in/')
            });
        } else {
            navigate('/Sign-in/')
        }
    }

    return (
        <div>
            <Helmet>
                <title>Pricing & Comparison | Surfheim VPN</title>
                <meta name="description" content="Explore Surfheim VPN's affordable pricing plans. Enjoy competitive rates, a free trial, and unmatched value. Compare Surfheim VPN pricing with ExpressVPN, NordVPN, and Surfshark. Find the best VPN plan for your needs." />
                <meta name="keywords" content="VPN pricing, affordable VPN, free trial, Surfheim VPN plans, VPN comparison, Surfheim VPN, ExpressVPN, NordVPN, Surfshark, Free VPN, Cheap VPN" />
                <meta property="og:title" content="Pricing | Surfheim VPN" />
                <meta property="og:description" content="Discover our budget-friendly VPN plans with a free trial. Experience superior online security and privacy at prices that beat the competition." />
                <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
                <link rel="canonical" href="https://www.surfheim.com/Pricing/" />
            </Helmet>

            <Header />
            
            <section className='section'>
                <div className='container mx-auto'>
                    <h1 className='h2 mb-8 lg:mb-16 text-center'>Choose a VPN Plan That Is Ideal for You</h1>

                    <div className='flex flex-col lg:flex-row lg:gap-x-[40px] gap-y-[30px] lg:gap-y-0 justify-center items-center'>
                        {plans.map((plan, planIndex) => {
                            const { title, services, price, initPrice, save } = plan;

                            return (
                                <div key={planIndex}>
                                    <div
                                        onClick={() => setIndex(planIndex)}
                                        className={`${planIndex === index ? 'bg-light/10 shadow-2xl border-[5px] border-accent' : 'border-[3px] border-grey'} w-[300px] md:w-[360px] rounded-[12px] p-[20px] md:p-[40px] cursor-pointer transition-all`}
                                    >
                                        <h2 className='text-[32px] font-semibold mb-4 md:mb-6 text-center'>{title}</h2>

                                        <div className='flex flex-col md:my-4 my-2 text-center'>
                                            <span className='line-through text-dark/55 text-xl'>{initPrice}</span>
                                            <div>
                                                <span className={`${planIndex === index ? 'text-4xl text-accent' : 'text-3xl'} font-semibold`}>{price}</span>
                                                <span className='text-xl text-dark/55 font-light'> / Month</span>
                                            </div>
                                            <span className={`${planIndex === index ? 'text-2xl text-accent font-bold' : 'text-xl text-dark/75 font-semibold'} mt-4`}>Save {save}</span>
                                        </div>

                                        <div className='flex flex-col gap-y-2 mb-4'>
                                            {services.map((service, index) => {
                                                const { name } = service;

                                                return (
                                                    <div key={index} className='flex justify-center items-center gap-x-[6px] text-[16px]'>
                                                        <HiCheck className='text-accent' />
                                                        <div>{name}</div>
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        <div className='flex justify-center items-center pt-3'>
                                            <button
                                                onClick={handleSubmit}
                                                disabled={loading}
                                                className={`${planIndex === index ? 'bg-accent hover:bg-accentHover text-white font-semibold' : 'border border-accent text-accent'} btn btn-sm space-x-[14px]`}
                                            >
                                                <span>Get This Plan</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>

            <h1 className='text-2xl md:text-4xl font-semibold mt-12 text-center'>Get VPN for the Best Price</h1>
            <p className='text-gray-700 text-center my-4'>Evaluate Surfheim VPN services against the competitors.</p>

            <div className='flex items-center justify-center my-8'>
                <table className="table-auto">
                    <thead>
                        <tr>
                            <th className="px-2 py-1"></th>
                            <th className="px-2 py-1 md:px-4 md:py-2 text-sm md:text-base lg:text-[20px] border border-gray-300 bg-accent/20">Surfheim</th>
                            <th className="px-2 py-1 md:px-4 md:py-2 text-sm md:text-base lg:text-[20px] border border-gray-300"><a href='https://www.expressvpn.com/order/' target="_blank" rel="noopener noreferrer">Express VPN</a></th>
                            <th className="px-2 py-1 md:px-4 md:py-2 text-sm md:text-base lg:text-[20px] border border-gray-300"><a href='https://nordvpn.com/pricing/' target="_blank" rel="noopener noreferrer">Nord VPN</a></th>
                            <th className="px-2 py-1 md:px-4 md:py-2 text-sm md:text-base lg:text-[20px] border border-gray-300"><a href='https://surfshark.com/pricing' target="_blank" rel="noopener noreferrer">Surfshark</a></ th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="px-2 py-1 md:px-4 md:py-2 text-sm md:text-base lg:text-[20px] border border-gray-300 font-semibold">Multiple Device Connection</td>
                            <td className="px-2 py-1 md:px-4 md:py-2 text-sm md:text-base lg:text-[18px] border border-gray-300 bg-accent/20">Unlimited</td>
                            <td className="px-2 py-1 md:px-4 md:py-2 text-sm md:text-base lg:text-[18px] border border-gray-300">5</td>
                            <td className="px-2 py-1 md:px-4 md:py-2 text-sm md:text-base lg:text-[18px] border border-gray-300">6</td>
                            <td className="px-2 py-1 md:px-4 md:py-2 text-sm md:text-base lg:text-[18px] border border-gray-300">Unlimited</td>
                        </tr>
                        <tr>
                            <td className="px-2 py-1 md:px-4 md:py-2 text-sm md:text-base lg:text-[20px] border border-gray-300 font-semibold">1-Month Plan</td>
                            <td className="px-2 py-1 md:px-4 md:py-2 text-sm md:text-base lg:text-[18px] border border-gray-300 bg-accent/20">$4.99</td>
                            <td className="px-2 py-1 md:px-4 md:py-2 text-sm md:text-base lg:text-[18px] border border-gray-300">$12.95</td>
                            <td className="px-2 py-1 md:px-4 md:py-2 text-sm md:text-base lg:text-[18px] border border-gray-300">$12.99</td>
                            <td className="px-2 py-1 md:px-4 md:py-2 text-sm md:text-base lg:text-[18px] border border-gray-300">$15.45</td>
                        </tr>
                        <tr>
                            <td className="px-2 py-1 md:px-4 md:py-2 text-sm md:text-base lg:text-[20px] border border-gray-300 font-semibold">1-Year Plan</td>
                            <td className="px-2 py-1 md:px-4 md:py-2 text-sm md:text-base lg:text-[18px] border border-gray-300 bg-accent/20">$2.49</td>
                            <td className="px-2 py-1 md:px-4 md:py-2 text-sm md:text-base lg:text-[18px] border border-gray-300">$8.33</td>
                            <td className="px-2 py-1 md:px-4 md:py-2 text-sm md:text-base lg:text-[18px] border border-gray-300">$5.99</td>
                            <td className="px-2 py-1 md:px-4 md:py-2 text-sm md:text-base lg:text-[18px] border border-gray-300">$3.69</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='container flex flex-col items-start justify-center mx-auto gap-y-2'>
                <p className='text-xs md:text-sm -mt-4 mx-4'>{"- The information presented in the comparison table is accurate as of September 2024."}</p>
                <p className='text-xs md:text-sm pb-10 mx-4'>{"- The details for each competitor may not encompass all features, functionalities, or quantities and are subject to modification."}</p>
            </div>

            <Footer />
        </div>
    )
};

export default PricingPage;