import React from 'react';
import Plans from './Components/Plans';
import Header from '../Header';
import Footer from '../Footer';


const Order = () => {
  return (
    <div className='overflow-hidden'>
        <Header />
        <Plans />
        <Footer />
    </div>
  );
};

export default Order;