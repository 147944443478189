import React, { useState} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import image from '../../../assets/img/image.png';


const Hero = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem('accessToken');

    if (token) {
      axios.get('https://api.surfheim.com/authentication/', {
          headers: { 'x-access-token': token }
      }).then(response => {
          if (response.status === 200) {
              navigate('/Order/')
          } else {
              navigate('/Sign-in/')
          }}
      ).catch(error => {
          setLoading(false);
          navigate('/Sign-in/')
      });
    } else {
      navigate('/Sign-in/')
    }
  }
  
  return (
    <section className=''>
      <div className='container mx-auto flex justify-center items-center'>
        <div className='flex flex-col lg:gap-x-[30px] gap-y-6 lg:gap-y-0 lg:flex-row md:flex-row items-center justify-center text-center lg:text-left'>
          <div className='flex-1'>
            <h1 className='title mb-2 lg:mb-5 mt-4 md:mt-0 '> Secure Your Browsing Experience</h1>
            <h3 className='lead mb-5 lg:mb-10 font-secondary'> Protect your data while you surf the internet.</h3>
            <div className='flex items-center max-w-sm lg:max-w-full mx-auto lg:mx-0 gap-x-2 lg:gap-x-6 font-secondary'>
              <button 
                onClick={handleSubmit}
                disabled={loading} 
                className='btn btn-md lg:btn-lg btn-accent flex justify-center items-center lg:gap-x-4'>
                  
                Get Surfheim
              </button>
              <span>Web, iOS and Android</span>
            </div>
          </div>
          <div className='flex-1'>
            <img src={image}  alt='hero'/>
          </div>
        </div>

      </div>
      
    </section>
  )
};

export default Hero;