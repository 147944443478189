import React from 'react';
import briefImg from '../../../assets/img/brief.png';



const Brief = () => {
  
  return (
    <section className='section bg-gradient-to-br from-active/15 via-accent to-active'>
      <div className='max-w-[1340px] mx-auto px-[25px]'>
        <div className='flex flex-col lg:flex-row lg:items-start items-center lg:gap-x-[30px]'>

          <div className='flex justify-between'>
            <img className='flex h-64 md:h-80' src={briefImg} alt='brief'/>

          </div>

          <div className='max-w-[640px] mx-auto text-center my-auto'>
            <h2 className='h2 text-white mb-6' >Why Surfheim VPN?</h2>
            <p className='text-xl lg:text-2xl text-white text-justify font-semibold' >Surfheim offers superior security features with modern encryption algorithms that protect user data more effectively than traditional VPNs. Additionally, the advanced obfuscation techniques employed by surfheim VPN facilitate the circumvention of internet restrictions, providing users with greater freedom online. To learn more <a className='underline' href='/About-us/'>click here.</a></p>
          </div>
        </div>

      </div>
    </section>
  )
};

export default Brief;