import React from 'react';
import { FaWindows, FaLinux, FaAndroid, FaApple, FaChrome, FaFirefox } from "react-icons/fa";
import { RiMacbookLine } from "react-icons/ri";
import { PiDotsThreeCircleFill } from "react-icons/pi";


const Devices = () => {

  return (
    <section className='py-8'>

      <h2 className='h2 text-center mb-3'>
        Available for all devices
      </h2>
      <h3 className='lead mb-5 lg:mb-10 text-center' >We provided a service that is available for every device, including mobile and PC.</h3>

      <div className='container justify-center mt-10 mx-auto lg:space-x-14 md:flex md:flex-row md:space-x-10 grid grid-cols-4 gap-4'>
        
        <div className='flex flex-col items-center'>
          <FaWindows className='text-2xl' />
          <div>Windows</div>
        </div>

        <div className='flex flex-col items-center'>
          <FaLinux className='text-2xl'/>
          <div>Linux</div>
        </div>

        <div className='flex flex-col items-center'>
          <RiMacbookLine className='text-2xl'/>
          <div>macOS</div>
        </div>

        <div className='flex flex-col items-center'>
          <FaAndroid className='text-2xl'/>
          <div>Android</div>
        </div>

        <div className='flex flex-col items-center'>
          <FaApple className='text-2xl'/>
          <div>iOS</div>
        </div>

        <div className='flex flex-col items-center'>
          <FaChrome className='text-2xl'/>
          <div>Chrome</div>
        </div>

        <div className='flex flex-col items-center'>
          <FaFirefox className='text-2xl' />
          <div>Firefox</div>
        </div>

        <div className='flex flex-col items-center'>
          <PiDotsThreeCircleFill className='text-2xl' />
          <div>Other</div>
        </div>

      </div>

      <div className='flex justify-center items-center pt-8' >
        <a href='/Downloads/'>
          <button className='btn btn-sm btn-outline lg:flex justify-center items-center shadow-lg'>
            Download App
          </button>
        </a>
      </div>

     
    </section>
  )
};

export default Devices;