import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';

const IP = () => {
  const [ip, setIp] = useState('Unknown');
  const [location, setLocation] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const userAgent = navigator.userAgent;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        setIp(data.ip);
        setLocation({
          city: data.city,
          region: data.region,
          country: data.country_name,
          timezone: data.timezone,
        });
        setUserInfo({
          asn: data.asn,
          organisation: data.org,
          os: navigator.userAgent,
          browser: navigator.userAgent,
        });
      } catch (error) {
        
      }
    };
  
    fetchData();
  }, []);
  


  return (
    <div>
      <Header />
      <div className="md:container bg-light/15 border border-gray-200 mx-8 md:mx-auto px-4 py-8 rounded-xl my-16">
        <div className='flex flex-row gap-x-2 mb-4'>
          <h2 className="text-xl font-medium text-black">IP Address:</h2>
          <p className="text-gray-500">{ip}</p>
        </div>
        
        <div className='gap-x-2 mb-4'>
          <h2 className="text-xl font-medium text-black">Location:</h2>
          <div className='flex flex-col ml-4'>
            <p className="text-gray-500">Geolocation: {location.city}, {location.region}, {location.country}</p>
            <p className="text-gray-500">Timezone{location.timezone}</p>
          </div>
          
        </div>
        
        <div className='flex flex-row gap-x-2 mb-4'>
          <h2 className="text-xl font-medium text-black">User Agent Info:</h2>
          <p className="text-gray-500">{userAgent}</p>
        </div>
        
        <div >
          <h2 className="text-xl font-medium text-black ">User Info:</h2>
          <p className="text-gray-500 ml-4">ASN: {userInfo.asn}</p>
          <p className="text-gray-500 ml-4">Organisation: {userInfo.organisation}</p>
          <p className="text-gray-500 ml-4">OS: {userInfo.os}</p>
          <p className="text-gray-500 ml-4">Browser: {userInfo.browser}</p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default IP;