import React from 'react';
import { FaWindows, FaLinux, FaAndroid, FaApple, FaChrome, FaFirefox, FaGooglePlay, FaAppStoreIos, FaGithub, FaSafari } from "react-icons/fa";
import { TbBrowserCheck } from "react-icons/tb";
import { RiMacbookLine } from "react-icons/ri";
import Header from '../Header';
import Footer from '../Footer';


const Downloads = () => {

  return (
    <div>
        <Header />
        <div>
            <h1 className="text-3xl md:text-4xl font-semibold mt-8 text-center">Download the Surfheim VPN app for all your devices</h1>
            <p className="text-gray-700 text-center my-4">To use the surfheim VPN service, download the app compatible with your device and follow the instructions. </p>
        </div>

        <div className='container flex flex-wrap mx-auto justify-center'>
            <div className='w-[300px] h-[300px] flex flex-col bg-light/25 m-8 rounded-xl'>
                <div className='flex flex-row text-center pt-8 justify-center items-center gap-x-4'>
                    <h1 className='text-2xl font-semibold'>Android</h1>
                    <FaAndroid className='text-2xl' />
                </div>
                <div>
                    <h2 className='text-xl font-semibold px-8 pt-4'>v2rayNG:</h2>
                    <a className='flex flex-row px-10 items-center gap-x-2' href='https://play.google.com/store/apps/details?id=com.v2ray.ang&hl=en&pli=1'>
                        <p>- Google Play</p>
                        <FaGooglePlay />
                    </a>
                    <a className='flex flex-row px-10 items-center gap-x-2' href='https://v2rayng.en.uptodown.com/android'>
                        <p>- Uptodown</p>
                    </a>
                </div>
                <div>
                    <h2 className='text-xl font-semibold px-8 pt-4'>ARMod</h2>
                    <a className='flex flex-row px-10 items-center gap-x-2' href='https://play.google.com/store/apps/details?id=com.artunnel57&hl=en'>
                        <p>- Google Play</p>
                        <FaGooglePlay />
                    </a>
                    
                </div>

                <div className='pt-8 underline text-blue-600 text-center'><a href='/'>Learn more here</a></div>
                
            </div>

            <div className='w-[300px] h-[300px] flex flex-col bg-light/25 m-8 rounded-xl'>
                <div className='flex flex-row text-center pt-8 justify-center items-center gap-x-4'>
                    <h1 className='text-2xl font-semibold'>iPhone</h1>
                    <FaApple className='text-2xl' />
                </div>
                <div>
                    <h2 className='text-xl font-semibold px-8 pt-4'>V2Box:</h2>
                    <a className='flex flex-row px-10 items-center gap-x-2' href='https://apps.apple.com/us/app/v2box-v2ray-client/id6446814690'>
                        <p>- App Store</p>
                        <FaAppStoreIos />
                    </a>
                    
                </div>
                {/* <div>
                    <h2 className='text-xl font-semibold px-8 pt-4'>v2RayTun</h2>
                    <a className='flex flex-row px-10 items-center gap-x-2' href='https://apps.apple.com/us/app/v2raytun/id6476628951'>
                        <p>- App Store</p>
                        <FaAppStoreIos />
                    </a>
                    
                </div> */}
                <div>
                    <h2 className='text-xl font-semibold px-8 pt-4'>Streisand</h2>
                    <a className='flex flex-row px-10 items-center gap-x-2' href='https://apps.apple.com/us/app/streisand/id6450534064'>
                        <p>- App Store</p>
                        <FaAppStoreIos />
                    </a>
                    
                </div>

                <div className='pt-14 underline text-blue-600 text-center'><a href='/'>Learn more here</a></div>
                
            </div>

            <div className='w-[300px] h-[300px] flex flex-col bg-light/25 m-8 rounded-xl'>
                <div className='flex flex-row text-center pt-8 justify-center items-center gap-x-4'>
                    <h1 className='text-2xl font-semibold'>Windows</h1>
                    <FaWindows className='text-2xl' />
                </div>
                <div>
                    <h2 className='text-xl font-semibold px-8 pt-4'>V2RayN:</h2>
                    <a className='flex flex-row px-10 items-center gap-x-2' href='https://github.com/2dust/v2rayN'>
                        <p>- Github</p>
                        <FaGithub />
                    </a>
                </div>
                <div>
                    <h2 className='text-xl font-semibold px-8 pt-4'>Nekoray</h2>
                    <a className='flex flex-row px-10 items-center gap-x-2' href='https://github.com/MatsuriDayo/nekoray'>
                        <p>- Github</p>
                        <FaGithub/>
                    </a>
                    
                </div>

                <div className='pt-14 underline text-blue-600 text-center'><a href='/'>Learn more here</a></div>
                
            </div>
            
            <div className='w-[300px] h-[300px] flex flex-col bg-light/25 m-8 rounded-xl'>
                <div className='flex flex-row text-center pt-8 justify-center items-center gap-x-4'>
                    <h1 className='text-2xl font-semibold'>Linux</h1>
                    <FaLinux className='text-2xl' />
                </div>
                <div>
                    <h2 className='text-xl font-semibold px-8 pt-4'>V2RayN:</h2>
                    <a className='flex flex-row px-10 items-center gap-x-2' href='https://github.com/2dust/v2rayN'>
                        <p>- Github</p>
                        <FaGithub />
                    </a>
                </div>
                <div>
                    <h2 className='text-xl font-semibold px-8 pt-4'>Nekoray</h2>
                    <a className='flex flex-row px-10 items-center gap-x-2' href='https://github.com/MatsuriDayo/nekoray'>
                        <p>- Github</p>
                        <FaGithub/>
                    </a>
                    
                </div>

                <div className='pt-14 underline text-blue-600 text-center'><a href='/'>Learn more here</a></div>
                
            </div>

            <div className='w-[300px] h-[300px] flex flex-col bg-light/25 m-8 rounded-xl'>
                <div className='flex flex-row text-center pt-8 justify-center items-center gap-x-4'>
                    <h1 className='text-2xl font-semibold'>MacOs</h1>
                    <RiMacbookLine className='text-2xl' />
                </div>
                <div>
                    <h2 className='text-xl font-semibold px-8 pt-4'>V2RayXS:</h2>
                    <a className='flex flex-row px-10 items-center gap-x-2' href='https://github.com/tzmax/V2RayXS'>
                        <p>- Github</p>
                        <FaGithub />
                    </a>
                </div>
                <div>
                    <h2 className='text-xl font-semibold px-8 pt-4'>Nekoray:</h2>
                    <a className='flex flex-row px-10 items-center gap-x-2' href='https://github.com/MatsuriDayo/nekoray'>
                        <p>- Github</p>
                        <FaGithub />
                    </a>
                    
                </div>

                <div className='pt-14 underline text-blue-600 text-center'><a href='/'>Learn more here</a></div>
                
            </div>

            <div className='w-[300px] h-[300px] flex flex-col bg-light/25 m-8 rounded-xl'>
                <div className='flex flex-row text-center pt-8 justify-center items-center gap-x-4'>
                    <h1 className='text-2xl font-semibold'>Browser</h1>
                    <TbBrowserCheck className='text-2xl' />
                </div>
                <div className='flex flex-row  items-center pt-4 gap-x-2'>
                    <h2 className='text-xl font-semibold pl-8 '>Chrome</h2>
                    <FaChrome />
                </div>
                <div className='flex flex-row  items-center pt-4 gap-x-2'>
                    <h2 className='text-xl font-semibold pl-8 '>Firefox</h2>
                        <FaFirefox />
                </div>
                <div className='flex flex-row  items-center pt-4 gap-x-2'>
                    <h2 className='text-xl font-semibold pl-8 pb-1'>Safari</h2>
                        <FaSafari />
                </div>

                <div className='pt-16 underline text-blue-600 text-center'><a href='/'>Learn more here</a></div>
                
            </div>

        </div>

        
      

        <Footer />
    </div>
  );
};

export default Downloads;