import React, { useState} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReactCountryFlag from "react-country-flag";



const Locations = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const token = localStorage.getItem('accessToken');

        if (token) {
        axios.get('https://api.surfheim.com/authentication/', {
            headers: { 'x-access-token': token }
        }).then(response => {
            if (response.status === 200) {
                navigate('/Order/')
            } else {
                navigate('/Sign-in/')
            }}
        ).catch(error => {
            setLoading(false);
            navigate('/Sign-in/')
        });
        } else {
        navigate('/Sign-in/')
        }
    }

  return (
    <section className='py-8'>

      <h2 className='h2 text-center mb-4 mt-8'>
        High-speed worldwide servers
      </h2>
      <p className='lead my-5 lg:mb-10 text-center' >Our VPN service offers a variety of locations from across the globe.</p>

      <div className='container justify-center mt-10 mx-auto lg:space-x-4 lg:flex lg:flex-row grid grid-cols-4 gap-4'>
        
      <div className='flex flex-col items-center text-[36px] sm:text-[48px]'>
            <ReactCountryFlag countryCode="US" aria-label="United States"/>
        </div>

        <div className='flex flex-col items-center text-[36px] sm:text-[48px]'>
            <ReactCountryFlag countryCode="CA" aria-label="Canada"/>
        </div>

        <div className='flex flex-col items-center text-[36px] sm:text-[48px]'>
            <ReactCountryFlag countryCode="DE" aria-label="Germany"/>
        </div>

        <div className='flex flex-col items-center text-[36px] sm:text-[48px]'>
            <ReactCountryFlag countryCode="GB" aria-label="United Kingdom"/>
        </div>

        <div className='flex flex-col items-center text-[36px] sm:text-[48px]'>
            <ReactCountryFlag countryCode="FR" aria-label="France"/>
        </div>

        <div className='flex flex-col items-center text-[36px] sm:text-[48px]'>
            <ReactCountryFlag countryCode="NL" aria-label="Netherlands"/>
        </div>

        <div className='flex flex-col items-center text-[36px] sm:text-[48px]'>
            <ReactCountryFlag countryCode="SE" aria-label="Sweden"/>
        </div>

        <div className='flex flex-col items-center text-[36px] sm:text-[48px]'>
            <ReactCountryFlag countryCode="TR" aria-label="Turkiye"/>
        </div>

        <div className='flex flex-col items-center text-[36px] sm:text-[48px]'>
            <ReactCountryFlag countryCode="PL" aria-label="Poland"/>
        </div>

        <div className='flex flex-col items-center text-[36px] sm:text-[48px]'>
            <ReactCountryFlag countryCode="FI" aria-label="Finland"/>
        </div>

                
        <div className='flex flex-col items-center text-[36px] sm:text-[48px]'>
            <ReactCountryFlag countryCode="ES" aria-label="Spain"/>
        </div>

        <div className='flex flex-col items-center text-[36px] sm:text-[48px]'>
            <ReactCountryFlag countryCode="JP" aria-label="Japan"/>
        </div>

        <div className='flex flex-col items-center text-[36px] sm:text-[48px]'>
            <ReactCountryFlag countryCode="SG" aria-label="Singapore"/>
        </div>

        <div className='flex flex-col items-center text-[36px] sm:text-[48px]'>
            <ReactCountryFlag countryCode="HK" aria-label="Hong Kong"/>
        </div>

        <div className='flex flex-col items-center text-[36px] sm:text-[48px]'>
            <ReactCountryFlag countryCode="AE" aria-label="United Arab Emirates"/>
        </div>

        <div className='flex flex-col items-center text-[36px] sm:text-[48px]'>
            <ReactCountryFlag countryCode="BR" aria-label="Brazil"/>
        </div>

      </div>

      <div className='flex justify-center items-center py-8' >
        <button 
            onClick={handleSubmit}
            disabled={loading}
            className='btn btn-sm btn-outline lg:flex justify-center items-center shadow-lg'>
          Get Surfheim Now
        </button>
      </div>

     
    </section>
  )
};

export default Locations;