import React from 'react';
import img2 from '../../../assets/img/feature2-img.png';

const Feature2 = () => {


  return (
    <section className='section'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row lg:items-start lg:gap-x-[30px]'>

          <div className='flex-1 order-2 lg:order-1'>
            <img src={img2} alt='feature'/>
          </div>

          <div className='flex-1 order-1 lg:order-2'>
            <h1 className='pretitle'>Zero Trust Policy</h1>
            <h2 className='title'>Your Privacy is our Top Priority</h2>
            <p className='lead text-justify'>We operate under a strict zero trust policy, ensuring that your data remains private and secure at all times. Our commitment to protecting your information means that we do not log user activity or share personal data with third parties. Experience peace of mind knowing that your online activities are safeguarded by our robust security measures.</p>
            <a href='/Features/'>
              <button className='btn-link flex items-center gap-x-3 hover:gap-x-5 transition-all'>
                Learn more  
              </button>
            </a>

          </div>
         

        </div>
      </div>
    </section>
  )
};

export default Feature2;