import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const TicketForm = () => {
    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [turnstileToken, setTurnstileToken] = useState('');
    
    const siteKey = '0x4AAAAAAAh7yiqQr45zLiE8';


    useEffect(() => {
        const turnstileScript = document.createElement('script');
        turnstileScript.src = `https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback`;
        document.body.appendChild(turnstileScript);
        window.onloadTurnstileCallback = () => {
            window.turnstile.render('#turnstile-container', {
                sitekey: siteKey,
                callback: turnstileCallback,
            });
        };

        const turnstileCallback = (token) => {
            setTurnstileToken(token);
        };

        return () => {
            document.body.removeChild(turnstileScript);
        };
    }, [siteKey]);


    useEffect(() => {
        const token = localStorage.getItem('accessToken');

        if (token) {
            axios.get('https://api.surfheim.com/authentication/', {
                headers: { 'x-access-token': token }
            }).then(response => {
                if (response.status !== 200) {
                    throw new Error('Token is not valid');
                }
            })
            .catch(error => {
                toast.error('Session expired, please sign in again.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                });
                setTimeout(() => {
                   navigate('/Sign-in/');
                }, 2000);
            });
        } else {
            toast.error('Please sign in.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
            });
            setTimeout(() => {
                navigate('/Sign-in/');
            }, 2000);
        }
    }, [navigate]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (loading || !turnstileToken) return;
        setLoading(true);

        const token = localStorage.getItem('accessToken');

        if (token) {
            axios.defaults.headers.common['x-access-token'] = token;
            axios.post('https://api.surfheim.com/Submit-ticket/', {
                "subject": subject,
                "description": description,
                'turnstileToken': turnstileToken
            }, { headers: { "Content-Type": "application/json" }}
        ).then(response => {
            if (response.status === 200) {
                toast.success('Ticket submitted successfully.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                });
                setTimeout(() => {
                    navigate('/profile/');
                }, 3000);
            }
        }).catch (error => {
            if (error.response && (error.response.data['message'] === 'Token is not valid' || error.response.data['message'] === 'Token has expired')) {
                toast.error('Session expired, please sign in again.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                });
                setTimeout(() => {
                    navigate('/Sign-up/');
                }, 2000);
            } else {
                toast.error(error.response.data, {
                    position: "top-center",
                    icon: 'error',
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                  setLoading(false)
                }
        });
    } else {
        toast.error('Something went wrong! Please try again.', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "colored",
        });
        setLoading(false)
    }
};

    return (
        <div className='md:container mx-auto'>
            <div className='flex flex-col px-8 sm:px-16 py-2 justify-evenly rounded-2xl my-4 mx-4 shadow-lg border border-dark/25'>
            <h1 className="text-2xl font-medium my-4 text-center font-secondary"> QuickAssist: Your Direct Line to Support </h1>
            <p className='font-semibold text-gray-500 mb-2 p-4 text-justify'>
                {"Welcome to our Ticketing System! If you need assistance or have a question, simply submit a ticket. Our support team will respond via email, keeping you updated on your request's status. You can easily track your ticket and get the help you need, all through your inbox. We're here to ensure your experience is smooth and efficient."}
            </p>
            <form onSubmit={handleSubmit} className="p-4">
                <div className="mb-4">
                    <label className="block text-gray-800">Subject</label>
                    <input
                        type="text"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        className="mt-2 block w-full border-gray-300 p-1 rounded-md border"
                        maxLength={180}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-800">Description</label>
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="mt-2 block w-full border p-1 border-gray-300 rounded-md"
                        maxLength={500}
                        required
                    />
                </div>
                <button 
                    disabled={loading}
                    type="submit" className="rounded-lg cursor-pointer bg-blue-500 border-blue-500 mt-4 text-white px-4 py-2.5 font-secondary font-semibold">
                    {loading ? 'Loading...' : 'Submit Ticket'}
                </button>

                <div id="turnstile-container" className="mt-3 items-center"></div>
            </form>
            </div>
            <ToastContainer />
        </div>
    );
};

export default TicketForm;