import React from "react";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home/Home';
import SignIn from "./pages/Sign/SignIn";
import SignUp from "./pages/Sign/SignUp";
import ForgetPass from "./pages/Sign/ForgetPass";
import ChangePassword from "./pages/Sign/ChangePass";
import Verify from "./pages/Sign/Verify";
import SetUser from "./pages/Sign/SetUser";
import Profile from "./pages/User/Profile";
import Ticketing from "./pages/User/Ticketing";
import Cookies from "./pages/Legal/Cookies";
import Privacy from "./pages/Legal/Privacy";
import Refund from "./pages/Legal/Refund";
import Terms from "./pages/Legal/Terms";
import AboutUS from "./pages/AboutUs";
import Features from "./pages/Features";
import DNS from "./pages/Tools/DnsLeak";
import IP from "./pages/Tools/IP";
import WebRTC from "./pages/Tools/WebRTC";
import Downloads from "./pages/User/Downloads";
import PricingPage from "./pages/Order/PricingPage";
import Order from "./pages/Order/Order";
import SuccessPay from "./pages/Order/SuccessPay";
import FaildPay from "./pages/Order/FailedPay";


const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/Pricing/',
    element: <PricingPage />
  },
  {
    path: '/Order/',
    element: <Order />
  },
  {
    path: '/Sign-in/',
    element: <SignIn />
  },
  {
    path: '/Sign-up/',
    element: <SignUp />
  },
  {
    path: '/Forget-Password/',
    element: <ForgetPass />
  },
  {
    path: '/Change-Password/',
    element: <ChangePassword />
  },
  {
    path: '/Verify-email/',
    element: <Verify />
  },
  {
    path: '/Complete-registration/',
    element: <SetUser/>
  },
  {
    path: '/Profile/',
    element: <Profile />
  },
  {
    path: '/Ticket/',
    element: <Ticketing />
  },
  {
    path: '/Terms-of-service/',
    element: <Terms />
  },
  {
    path: '/Privacy-policy/',
    element: <Privacy />
  },
  {
    path: '/Cookies-and-preferences/',
    element: <Cookies />
  },
  {
    path: '/Refund-policy/',
    element: <Refund />
  },
  {
    path: '/About-us/',
    element: <AboutUS />
  },
  {
    path: '/Features/',
    element: <Features />
  },
  {
    path: '/webrtc-leak/',
    element: <WebRTC />
  },
  {
    path: '/DNS-leak-test/',
    element: <DNS />
  },
  {
    path: '/Check-IP/',
    element: <IP />
  },
  {
    path: '/Downloads/',
    element: <Downloads />
  },
  {
    path: '/Successful-payment',
    element: <SuccessPay />
  },
  {
    path: '/Failed-payment/',
    element: <FaildPay />
  },
])

const App = () => {
 
  return (
    <div>

      <RouterProvider router={router} />

    </div>
  );
};

export default App;
