import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { FaCircleCheck } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';


const SuccessfulPay = () => {
  const navigate = useNavigate();

  const handleReturnProfile = () => {
    navigate('/Profile/');
  };

  return (
    <div className='overflow-hidden'>
        <Header />
        <div className='flex flex-col justify-center items-center py-12 bg-light/10'>
          <div className="flex flex-col justify-center items-center w-[90%] sm:w-[360px] h-[400px] border border-gray-300 bg-white shadow-xl rounded-xl">
            <FaCircleCheck className='text-green-700 text-[64px] mb-4' />
            <h2 className='text-2xl font-bold text-center mb-14'>Payment Successful</h2>
            <p className='text-center text-gray-700 mb-2'>
              Thanks.
            </p>
            <p className='text-center text-gray-700 mb-14'>
              Your Payment have been received!
            </p>
            <button 
              onClick={handleReturnProfile} 
              className='btn btn-sm btn-outline lg:flex justify-center items-center shadow-lg'
            >
              Return to Profile
            </button>
          </div>
        </div>
        <Footer />
    </div>
  );
};

export default SuccessfulPay;