import React, { useState } from 'react';
import OrderSummary from './OrderSummary';


const plans = [
  { id:1, title: '1-Month', initPrice:'$9.99', price: '$4.99', totalPrice: '4.99', totalInitPrice:'$9.99', moneyBack: '7-days', save: '50%', description: 'Billed for 30 days, renews at $4.99 per month. Without any VAT/Sales tax or extra fees.', services: [{ name: 'High-speed, Secure VPN' },{ name: 'Malware and threat protection'},{ name: 'Unlimited traffic' }]},
  { id:2, title: '1-Year', initPrice:'$9.99', price: '$2.49', totalPrice: '29.88', totalInitPrice:'$119.88', moneyBack: '30-days', save: '75%', description: 'Billed for 365 days, renews at $2.49 per month. Without any VAT/Sales tax or extra fees.', services: [{ name: 'High-speed, Secure VPN' },{ name: 'Malware and threat protection'},{ name: 'Unlimited traffic' }]},
  { id:3, title: '6-Months', initPrice:'$9.99', price: '$3.99', totalPrice: '23.94', totalInitPrice:'$59.94', moneyBack: '30-days', save: '60%', description: 'Billed for 180 days, renews at $3.99 per month. Without any VAT/Sales tax or extra fees.', services: [{ name: 'High-speed, Secure VPN' },{ name: 'Malware and threat protection'},{ name: 'Unlimited traffic' }]},
];

const Plans = () => {
  const [selectedPlan, setSelectedPlan] = useState(plans[1]);
  
  const handlePlanChange = (plan) => {
    setSelectedPlan(plan);
  };


  return (

    <div className="container mx-auto flex flex-col">
      
      <h2 className='h2 my-8 lg:mb-16 text-center'>Get surfheim VPN in 2 simple steps</h2>

      <div className='flex flex-row font-semibold font-secondary'>
        <div className='text-white bg-accentHover w-8 h-8 mx-4 mb-4 flex justify-center items-center rounded-full'>1</div>
        <div className='text-xl text-accentHover pb-4'>Select Your Preferred Plan:</div>
      </div>


      <div>
        <div className='flex flex-col lg:flex-row lg:gap-x-[40px] gap-y-[30px] lg:gap-y-0 justify-center items-center'>
          {plans.map((plan, planIndex) => {
            const { title, price, initPrice, save, moneyBack} = plan;
            
            return (
              <div key={planIndex}>

              <div onClick={() => handlePlanChange(plan)} 
                className={`${ selectedPlan === plan ? 'bg-light/10 shadow-2xl border-[5px] border-accent' : 'border-[3px] border-grey' } w-[280px] md:w-[320px] rounded-[12px] p-[20px] md:p-[40px] cursor-pointer transition-all`}>
                    
                <div className='text-[32px] font-semibold mb-4 md:mb-6 text-center'>{title}</div>

                <div className='flex flex-col md:my-4 my-2 text-center'>
                  <span className='line-through text-dark/55 text-xl'>{initPrice}</span>
                  <div >
                      <span className={`${ selectedPlan === plan ? 'text-4xl text-accent' : 'text-3xl'} font-semibold `}>{price}</span>
                      <span className='text-xl text-dark/55 font-light'> / Month</span>
                  </div>
                  <span className={`${ selectedPlan === plan ? 'text-2xl text-accent font-bold' : 'text-xl text-dark/75 font-semibold'}  mt-4`}>save {save}</span>
                </div>

                <div className='text-base text-dark/55 text-center'>{moneyBack} money-back guarantee</div>

              </div>
            </div> 
          )
          })}
        </div>
      </div>

      {selectedPlan && (
        <OrderSummary plan={selectedPlan} />
        )}

    </div>
  );
};

export default Plans;