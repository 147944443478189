import React, { useState } from 'react';
import { RiShieldCheckFill } from "react-icons/ri";
import bitcoin from '../../../assets/coins/bitcoin.png';
import ethereum from '../../../assets/coins/ethereum.png';
import tether from '../../../assets/coins/tether.png';
import bnb from '../../../assets/coins/bnb.png';
import solana from '../../../assets/coins/solana.png';
import monero from '../../../assets/coins/monero.png';
import usdc from '../../../assets/coins/usdc.png';
import oxapay from '../../../assets/img/oxapay.png';
import btcpay from '../../../assets/img/btcpay.png';
import plisio from '../../../assets/img/plisio.png';
import { BsChevronDown } from 'react-icons/bs';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const OrderSummary = ({ plan }) => {
    const [isOpen1, setIsOpen1] = useState(true);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);

    
    const handlePayment = async () => {
        const token = localStorage.getItem('accessToken');
        const plan_name = plan.title
        const plan_price = plan.totalPrice
        try {
            axios.defaults.headers.common['x-access-token'] = token;
            const response = await axios.post('https://api.surfheim.com/Oxapay-Payment/', {
                "plan": plan_name,
                "price": plan_price,
            },{headers: {'Content-Type': 'application/json',}
        });
        const { payLink } = response.data;
          window.location.href = payLink;
        } catch (error) {
            toast.error('Unknown Error!', {
                position: 'top-center',
                icon: 'error',
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });   
        }   
      };

    const handlePlisioPayment = async () => {
        const token = localStorage.getItem('accessToken');
        const plan_name = plan.title
        const plan_price = plan.totalPrice
        try {
            axios.defaults.headers.common['x-access-token'] = token;
            const response = await axios.post('https://api.surfheim.com/Plisio-Payment/', {
                "plan": plan_name,
                "price": plan_price,
        },{
            headers: {
                'Content-Type': 'application/json',
            },
        });
    
          const { payLink } = response.data;
          window.location.href = payLink;
        } catch (error) {
            toast.error('Unknown Error!', {
                position: 'top-center',
                icon: 'error',
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });   
        }   
      };

    const handleBtcpayPayment = async () => {
        toast.error('Service is temporarily unavailable!', {
            position: 'top-center',
            icon: 'error',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        });   
      };


  const handleToggle1 = () => {
    setIsOpen1(!isOpen1);
    setIsOpen2(false);
    setIsOpen3(false);
  };

  const handleToggle2 = () => {
    setIsOpen2(!isOpen2);
    setIsOpen1(false);
    setIsOpen3(false);
  };

  const handleToggle3 = () => {
    setIsOpen3(!isOpen3);
    setIsOpen1(false);
    setIsOpen2(false);
  };


  return (
    <section className="my-10">
        <div className='flex flex-row items-center font-semibold font-secondary mt-8'>
            <div className='text-white bg-accentHover w-8 h-8 mx-4 mb-4 flex justify-center items-center rounded-full'>2</div>
            <div className='text-xl text-accentHover pb-4'>Review selected plan and choose payment method:</div>
        </div>

        
        <div className='flex flex-col lg:flex-row items-center justify-center mt-4 gap-x-8 md:gap-x-16 lg:gap-x-24 gap-y-8 md:gap-y-0'>
            
            <div className='flex flex-col'>
                <div className='bg-white border-[2px] border-gray-200 rounded-xl w-[340px] sm:w-[360px] md:w-[400px] lg:w-[480px] pb-4'>
                    <h2 className="text-[24px] text-dark font-semibold px-6 py-4 font-secondary">Order Summary</h2>
                    <div className='flex flex-row justify-between px-6'>
                        <p className="text-[20px] text-dark/75 font-semibold">{plan.title} Plan</p>
                        <div className='flex flex-row gap-x-3'>
                            <p className="text-[20px] line-through text-dark/45 font-semibold">{plan.initPrice}</p>
                            <p className="text-[20px] text-dark/75 font-semibold">{plan.price} / month</p>
                        </div>
                    </div>

                    <hr className='mx-6 my-4'/>

                    <div className='flex flex-row justify-between px-6'>
                        <h2 className="text-[22px] font-semibold text-dark">Total bill:</h2>
                        <div className='flex flex-row gap-x-3'>
                            <p className="text-[20px] line-through text-red-600 font-medium">{plan.totalInitPrice}</p>
                            <p className="text-[20px] text-green-600 font-bold">${plan.totalPrice}</p>
                        </div>
                        
                    </div>

                    <div className='mx-6 my-4'>
                        <p className='text-sm font-semibold'>Note:</p>
                        <p className="text-sm font-medium text-dark/85 align-middle">{plan.description}</p>
                    </div>
                    
                    <div className='mx-6 mt-6 flex flex-row align-middle bg-accent/15 p-2 rounded-lg'>
                        <RiShieldCheckFill  className='text-green-600 text-[36px] pb-3'/>
                        <p className="text-sm font-medium text-dark/65 ml-2 align-baseline"> To ensure your complete satisfaction, we offer a full refund within {plan.moneyBack}, if you are not entirely happy with our service.</p>
                    </div>
                </div>

                <div className='flex flex-col'>
                    <div className='w-[340px] sm:w-[360px] md:w-[400px] lg:w-[480px]'>
                        <p className='text-sm font-semibold mx-4 pt-4'>* Note:</p>
                        <p className="text-sm font-medium text-dark/80 mx-6 mt-1"> - Fees associated with the blockchain network might be applicable</p>
                        <p className="text-sm font-medium text-dark/80 mx-6 mt-1"> - The processing of payments may take up to 24 hours.</p>
                        <p className="text-sm font-medium text-dark/80 mx-6 mt-1"> - You will receive an email confirmation once your service is activated.</p>
                        <p className="text-sm font-medium text-dark/80 mx-6 mt-1"> - By proceeding with your purchase, you accept our <a href='/Terms-of-service/' className='text-blue-600 underline'>Terms of Service</a>.</p>

                    </div>
                    
                </div>
                   
            </div>       

            <div className="flex flex-col bg-white border-[2px] border-gray-200 rounded-lg w-[340px] sm:w-[360px] md:w-[400px] lg:w-[480px] lg:mt-0 mt-4 md:mb-4 pb-4">
                <span className='text-[24px] text-dark font-secondary font-semibold px-6 py-4'>Pay with Preferred Option</span>
                
                <div className='flex flex-row justify-between items-center px-6'>
                    <span className='text-[16px] sm:text-[22px] text-dark/75 font-semibold'>Cryptocurrencies</span>
                    <div className='flex flex-row gap-x-1'> 
                        <img className='h-6 rounded-full' src={bitcoin} alt='bitcoin'/>
                        <img className='h-6 rounded-full' src={ethereum} alt='ethereum'/>
                        <img className='h-6 rounded-full' src={tether} alt='tether'/>
                        <img className='h-6 rounded-full' src={bnb} alt='bnb'/>
                        <img className='hidden h-6 rounded-full' src={solana} alt='solana'/>
                        <img className='hidden h-6 rounded-full' src={monero} alt='monero'/>
                        <img className='hidden h-6 rounded-full' src={usdc} alt='usdc'/>
                    </div>
                </div>

                <hr className='mx-6 my-4'/>

                <div className='flex flex-col mx-6 gap-y-2'>
                    <span className='text-[20px] text-dark/85 font-semibold border-black'>Select provider:</span>
                    
                    <div className='flex flex-col gap-y-2'>
                        <div className="relative bg-light/35 rounded-xl">
                            <div className='flex flex-row justify-between items-center'>
                                <button className="w-full font-bold"
                                    onClick={handleToggle1}>
                                        <div><img className='h-10 my-2' src={oxapay} alt='oxapay'/></div>
                                </button>
                                <BsChevronDown className='mr-2'/>
                            </div>

                            {isOpen1 && (
                                
                                <div className=' w-full flex flex-col border-dark' >
                                    <hr className=' my-2 border-dark/35'/>
                                    <div className='flex flex-col mx-4 mb-2'>
                                        <p className='text-sm font-medium text-dark/85'>Continue to buy VPN with oxapay</p>
                                        <p className='text-sm font-medium text-dark/85'>+20 coins are supported</p>
                                        <button onClick={handlePayment} className="text-md rounded-3xl cursor-pointer bg-accent text-white py-1  mx-auto px-4 lg:mx-22 my-2 font-secondary font-semibold">
                                            Complete purchase
                                        </button>
                                    </div>
                                </div>
                                )}
                        </div>

                        <div className="relative bg-light/35 rounded-xl">
                            <div className='flex flex-row justify-between items-center'>
                                <button className="w-full font-bold"
                                    onClick={handleToggle3}>
                                        <div><img className='h-10 ml-3 my-1' src={plisio} alt='plisio'/></div>
                                </button>
                                <BsChevronDown className='mr-2'/>
                            </div>

                            {isOpen3 && (
                                <div className=' w-full flex flex-col border-dark' >
                                    <hr className=' my-2 border-dark/35'/>
                                    <div className='flex flex-col mx-4 mb-2'>
                                        <p className='text-sm font-medium text-dark/85'>Continue to buy VPN with Plisio</p>
                                        <p className='text-sm font-medium text-dark/85'>+20 coins are supported</p>
                                        <button onClick={handlePlisioPayment} className="text-md rounded-3xl cursor-pointer bg-accent text-white py-1 mx-auto px-4 lg:mx-24 my-2 font-secondary font-semibold">
                                            Complete purchase
                                        </button>
                                    </div>
                                </div>
                                )}
                        </div>

                        <div className="relative bg-light/35 rounded-xl">
                            <div className='flex flex-row justify-between items-center'>
                                <button className="w-full font-bold" onClick={handleToggle2}>
                                    <div><img className='h-10 ml-2 my-1' src={btcpay} alt='btcpay'/></div>
                                </button>
                                
                                <BsChevronDown className='mr-2'/>
                            </div>

                            {isOpen2 && (
                                <div className=' w-full flex flex-col border-dark' >
                                    <hr className=' my-2 border-dark/35'/>
                                    <div className='flex flex-col mx-4 mb-2'>
                                        <p className='text-sm font-medium text-dark/85'>Continue to buy VPN with BTCpay</p>
                                        <p className='text-sm font-medium text-dark/85'>Pay with Bitcoin</p>
                                        <button onClick={handleBtcpayPayment} className="text-md rounded-3xl cursor-pointer bg-accent text-white py-1  mx-auto px-4 lg:mx-24 my-2 font-secondary font-semibold">
                                            Complete purchase
                                        </button>
                                    </div>
                                </div>
                                )}
                        </div>
                            
                    </div>
                    

                </div>
            </div>
        </div>
        <ToastContainer />
               
    </section>
  );
};

export default OrderSummary;