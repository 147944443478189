import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../Header';
import Footer from '../Footer';


const DNS = () => {
  const [loading, setLoading] = useState(false);
  const [dnsLeakStatus, setDnsLeakStatus] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios.get('https://dnsleaktest.com/api/v1/test')
      .then(response => {
        setDnsLeakStatus(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  return (
    <div>

      <Header />
    
      <div className="md:container bg-light/15 border border-gray-200 mx-8 md:mx-auto px-4 py-8 rounded-xl my-16">
        <h1 className="text-2xl font-bold mb-4">DNS Leak Check</h1>
        {loading ? (
          <div className="flex items-center">
            <svg className="animate-spin h-5 w-5 mr-3 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span className="text-blue-500">Checking DNS leak status...</span>
          </div>
        ) : error ? (
          <p>Error: {error.message}</p>
        ) : dnsLeakStatus ? (
          <div>
            <p>Your DNS server is: {dnsLeakStatus.dns}</p>
            <p>Your IP address is: {dnsLeakStatus.ip}</p>
          </div>
        ) : (
          <p>Failed to fetch DNS leak status.</p>
        )}
      </div>

      <div className="md:container bg-light/15 border border-gray-200 mx-8 md:mx-auto px-4 py-8 rounded-xl my-8">
        <h2 className="text-2xl font-semibold mb-4">What is a DNS Leak Test?</h2>
          <p className="text-gray-700 mb-8 ml-4">
            {"A DNS leak test is a diagnostic tool used to determine whether your internet traffic, specifically your DNS requests, are being routed through the intended DNS servers when using a Virtual Private Network (VPN) or proxy service. The primary purpose of this test is to check for potential vulnerabilities in your online privacy and security."}
          </p>

          <h2 className="text-2xl font-semibold mb-4">Why Use a DNS Leak Test?</h2>

          <div className='flex flex-col'>
            <div className='flex flex-row ml-4'>
              <h2 className="text-xl font-medium">Privacy Protection:</h2>
              <p className="text-gray-700 ml-2"> Ensures that your browsing history remains private and not exposed to ISPs or third parties.</p>
            </div>

            <div className='flex flex-row ml-4'>
              <h2 className="text-xl font-medium">Security Assurance:</h2>
              <p className="text-gray-700 ml-2"> Confirms that your VPN is functioning correctly and providing the level of protection expected.</p>
            </div>

            <div className='flex flex-row ml-4'>
              <h2 className="text-xl font-medium">Troubleshooting Tool:</h2>
              <p className="text-gray-700 ml-2"> Helps identify misconfigurations in VPN settings or network issues that may lead to leaks.</p>
            </div>
          </div>
          
      </div>

      <Footer />
    </div>
  );
};

export default DNS;